import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import { auth } from "../components/Firebase/Firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import View from "../components/View";
import Layout from "../components/Layout";

const Reset = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      navigate(`/app/login`);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Layout title="Kennwort vergessen">
    <View >
      <h1>Kennwort zurücksetzen</h1>
      <p>Sie bekommen per E-Mail einen Link zum Zurücksetzen Ihres Kennworts. </p>
      <div className="login">
        <div className="login__container">
          {error && <div className="auth__error">{error}</div>}
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-Mail-Adresse"
            />
            <button>Kennwort vergessen</button>
          </form>
          <h4>Weitere Aktionen</h4>
            <ul>
              <li>
                <Link to="/">Anmelden</Link>
              </li>
              <li><Link to="/register">Auf der Website registrieren</Link>
              </li>
            </ul>
        </div>
      </div>
    </View>
    </Layout>
  );
};

export default Reset;